import React from 'react';

import styled from 'styled-components';
import { Popover, Menu, Input, Avatar, Progress, Button } from 'antd';
import {
    LineChartOutlined,
    AppstoreOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { Discord2SVG } from '../../Misc/svgs';
import { Auth, Layout as LayoutState } from '../../States';
import { API_HOST } from '../../index';

const { SubMenu } = Menu;

export const ProfileButton = () => {
    const auth = Auth.useContainer();
    const layout = LayoutState.useContainer();

    const loginRedirect = () => {
        if (!auth.state.loading && auth.isLoggedIn) return;
        window.location.href = `${API_HOST}/v1/auth/login`;
    };

    const content = auth.state.id ? (
        <StyledMenu id='ProfileMenu' mode='inline' theme='dark'>
            <SubMenu
                icon={<LineChartOutlined />}
                key='subProfile1'
                title='Level 5'
            >
                <StyledProgress percent={50} status='active' />
                <StyledXP>362/743xp</StyledXP>
            </SubMenu>
            <SubMenu
                key='subProfile2'
                icon={<AppstoreOutlined />}
                title='Your Code'
            >
                <StyledSearch placeholder='Search' />
                <Menu.Item key='1'>Example 1</Menu.Item>
                <Menu.Item key='2'>Example 2</Menu.Item>
                <Menu.Item key='3'>Example 3</Menu.Item>
                <Menu.Item key='4'>Example 4</Menu.Item>
                <Menu.Item key='5'>Example 5</Menu.Item>
                <Menu.Item key='6'>Example 6</Menu.Item>
            </SubMenu>
            <Menu.Item key='Profile1' danger onClick={auth.logout}>
                Logout
            </Menu.Item>
        </StyledMenu>
    ) : (
        <StyledMenu id='ProfileMenu' mode='inline' theme='dark'>
            <i>Please login to view your information</i>
        </StyledMenu>
    );

    return (
        <Popover
            content={content}
            title={`Hello, ${auth.state.username || 'Guest'}`}
            placement='leftTop'
        >
            {auth.state.loading ? (
                <StyledButton ghost icon={<LoadingOutlined />}>
                    Loading...
                </StyledButton>
            ) : (
                <StyledButton ghost onClick={loginRedirect}>
                    {auth.isLoggedIn ? (
                        <StyledAvatar
                            size='small'
                            src={`https://cdn.discordapp.com/avatars/${auth.state.id}/${auth.state.avatar}.png`}
                        />
                    ) : (
                        <StyledDiscord2SVG />
                    )}
                    {!layout.state.collapsed && (
                        <StyledUsername>
                            {auth.state.username || 'Login'}
                        </StyledUsername>
                    )}
                </StyledButton>
            )}
        </Popover>
    );
};

const StyledUsername = styled.span`
    vertical-align: middle;
    margin-left: 5px;
`;

const StyledAvatar = styled(Avatar)`
    vertical-align: middle;
`;

const StyledDiscord2SVG = styled(Discord2SVG)`
    width: 24px;
    vertical-align: middle;
    fill: white;
`;

const StyledSearch = styled(Input.Search)`
    margin: 5%;
    width: 90%;
`;

const StyledProgress = styled(Progress)`
    margin: 5px;
`;

const StyledXP = styled.p`
    margin-top: 5px;
    textalign: center;
`;

const StyledButton = styled(Button)`
    margin: 5%;
    width: 90%;
    background-color: #7289da;
    border: none;
`;

const StyledMenu = styled(Menu)`
    margin: 0px;
    width: 256px;
    .ant-menu-submenu .ant-menu-sub {
        background-color: rgb(31, 31, 31);
    }
`;
