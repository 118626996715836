import React from 'react';
import { DiscordIcon } from '../../Misc/svgs';
import history from '../../history';
import { Layout, Menu, Divider } from 'antd';
import {
    HistoryOutlined,
    FireOutlined,
    CodeOutlined,
    GlobalOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { ProfileButton } from './ProfileButton';
import { Layout as LayoutState } from '../../States';

const { SubMenu } = Menu;
const { Sider } = Layout;
export const Sidebar = () => {
    const layout = LayoutState.useContainer();

    return (
        <Sider
            collapsible
            collapsed={layout.state.collapsed}
            onCollapse={layout.toggleCollapsed}
            style={{ height: '100vh' }}
            collapsedWidth={window.innerWidth >= 576 ? 80 : 0}
        >
            <Menu
                style={{ maxWidth: 256, height: '100vh' }}
                defaultOpenKeys={['sub1']}
                mode='inline'
                className='navbar'
                theme='dark'
                inlineCollapsed={layout.state.collapsed}
                onClick={({ key }) => {
                    history.push(key);
                }}
            >
                <ProfileButton />
                <Divider style={{ margin: 0 }} />
                <SubMenu key='sub1' title='Source Code' icon={<CodeOutlined />}>
                    <Menu.Item key='/snippets' icon={<FireOutlined />}>
                        Trending
                    </Menu.Item>
                    <Menu.Item
                        key='/snippets?u=Recent'
                        icon={<HistoryOutlined />}
                    >
                        Recent
                    </Menu.Item>
                    <Menu.Item key='/search' icon={<SearchOutlined />}>
                        Search
                    </Menu.Item>
                </SubMenu>
                <SubMenu key='sub5' icon={<GlobalOutlined />} title='Ecosystem'>
                    <Menu.Item key='/submit'>Submit Source Code</Menu.Item>

                    <Menu.Item key='/request'>Request Source Code</Menu.Item>
                    <Menu.Item key='/bounties'>Bounty Board</Menu.Item>
                </SubMenu>
                <Menu.Item key='20' icon={<DiscordIcon />}>
                    <a
                        href='https://discord.gg/plexidev'
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        Discord
                    </a>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};
