import React, { useState } from 'react';
import { Switch } from 'react-router-dom';
import { Layout, Button } from 'antd';
import styled from 'styled-components';

import { Route } from './Route';
import { Auth } from '../States';

import { Sidebar } from './Layout/Sidebar';

import { Changelog } from '../Drawers';
import { Search, Snippet, Submit, Snippets, Edit } from '../Pages';

const { Content, Footer } = Layout;

export const App = () => {
    const AuthContainer = Auth.useContainer();
    const [state, setState] = useState({ changelogVisible: false });

    return (
        <Layout>
            <Sidebar />
            <Layout>
                <StyledContent>
                    <StyledLayout>
                        <Switch>
                            <Route path='/' exact component={Snippets} />
                            <Route path='/search' exact component={Search} />
                            <Route
                                path='/snippet/:id'
                                exact
                                component={Snippet}
                            />
                            <Route
                                path='/snippets'
                                exact
                                component={Snippets}
                            />
                            <Route
                                protected
                                path='/submit'
                                exact
                                component={Submit}
                            />
                            <Route
                                protected
                                path='/edit/:id'
                                exact
                                component={Edit}
                            />
                            <Route
                                path='/callback/:token'
                                component={AuthContainer.Callback}
                            />
                        </Switch>
                    </StyledLayout>
                </StyledContent>
                <StyledFooter>
                    <a
                        href='https://github.com/TrueXPixels/sourcecode-public/issues/new/choose'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Button type='link'>Issues/Feedback?</Button>
                    </a>
                    &bull;
                    <Button
                        type='link'
                        onClick={() =>
                            setState({
                                ...state,
                                changelogVisible: !state.changelogVisible
                            })
                        }
                    >
                        Changelog
                    </Button>
                    <Changelog
                        onClose={() =>
                            setState({
                                ...state,
                                changelogVisible: !state.changelogVisible
                            })
                        }
                        visible={state.changelogVisible}
                    />
                </StyledFooter>
            </Layout>
        </Layout>
    );
};

const StyledFooter = styled(Footer)`
    background: #333333 !important;
    padding: 5px 10px;
    text-align: right;
`;

const StyledContent = styled(Content)`
    background: #333333 !important;
    overflow-x: auto;
`;

const StyledLayout = styled.div`
    padding: 24px;
    min-height: 360px;
`;
