import React from 'react';
import { Drawer, Timeline } from 'antd';

const { Item } = Timeline;

export const Changelog = props => {
    return (
        <Drawer {...props} title='Changelog'>
            <Timeline>
                <Item color='red'>
                    June 26th &bull; Fixed issues on mobile displays
                </Item>
                <Item>June 26th &bull; Added changelog</Item>
                <Item>June 25th &bull; Added view counter</Item>
                <Item>June 25th &bull; Added ability to delete snippets</Item>
                <Item>June 25th &bull; Added ability to edit snippets</Item>
            </Timeline>
        </Drawer>
    );
};
