import React from 'react';

import ReactDOM from 'react-dom';

import { App } from './Components/App';

import { Router } from 'react-router-dom';

import 'antd/dist/antd.dark.css';

import history from './history';

import { Auth, Layout } from './States';

export const API_HOST = window.location.host.includes('plexidev.org')
    ? 'https://sc-api.plexidev.org'
    : 'http://localhost:3000';

ReactDOM.render(
    <Router history={history}>
        <Layout.Provider>
            <Auth.Provider>
                <App />
            </Auth.Provider>
        </Layout.Provider>
    </Router>,
    document.getElementById('root')
);
