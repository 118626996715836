import React, { Fragment, useState, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/vsDark';
import { Form, Input, Button, Row, Col, Select, Divider, message } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import history from '../history';
import { API_HOST } from '../index';

const { Option } = Select;

export const Edit = ({ match }) => {
    const { id } = match.params;
    const [value, setValue] = useState('// paste your code here');
    const [data, setData] = useState({ loading: true });
    const [defaults, setDefaults] = useState({
        languages: [],
        topics: [],
        libraries: []
    });
    const [names, setNames] = useState({});

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                var response = await fetch(`${API_HOST}/v1/metadata/top`);
                var results = await response.json();
            } catch (e) {
                console.log(e);
                message.error('Unable to fetch metadata, try again later.');
            }

            if (results && response.status === 200) setDefaults(results);
        };
        fetchMetadata();
    }, []);

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                var response = await fetch(`${API_HOST}/v1/snippets/${id}`);
                var results = await response.json();
            } catch (e) {
                console.log(e);
                message.error('Unable to fetch snippet, try again later.');
            }

            if (results && response.status === 200) {
                setData(results);
                setValue(results.code);
            }
        };
        fetchMetadata();
    }, [id]);

    const onFinish = async values => {
        try {
            var response = await fetch(`${API_HOST}/v1/snippets/${id}`, {
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'X-Requested-With',
                    Authentication: localStorage.token
                },
                body: JSON.stringify(values)
            });
            var results = await response.json();
        } catch (e) {
            console.log(e);
            message.error('Unable to edit form, try again later.');
        }

        console.log(results);

        if (!results || results.message)
            message.error(
                (results || {}).message ||
                    'Unable to edit form, try again later.'
            );
        else history.push(`/snippet/${results.id}`);
    };

    const styles = {
        root: {
            boxSizing: 'border-box',
            fontFamily: '"Dank Mono", "Fira Code", monospace',
            ...theme.plain
        }
    };

    const highlight = code => (
        <Highlight {...defaultProps} theme={theme} code={code} language='jsx'>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Fragment style={{ overflow: 'scroll !important' }}>
                    {tokens.map((line, i) => (
                        <Line key={i} {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </Line>
                    ))}
                </Fragment>
            )}
        </Highlight>
    );

    if (data.loading) return '';
    else
        return (
            <StyledRow type='flex' justify='center' align='middle'>
                <Col sm={16} md={8}>
                    <Form
                        onFinish={onFinish}
                        name='basic'
                        initialValues={{
                            ...data
                        }}
                        layout='vertical'
                    >
                        <Form.Item
                            label='Title'
                            name='title'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the title!'
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Description'
                            name='description'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a description!'
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Language'
                            name='language'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a language!'
                                }
                            ]}
                        >
                            <Select
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <StyledDivider />
                                        <StyledSelectDiv>
                                            <StyledInput
                                                value={names.languages}
                                                onChange={event => {
                                                    setNames({
                                                        ...names,
                                                        languages:
                                                            event.target.value
                                                    });
                                                }}
                                            />
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                            <StyledAddButton
                                                href='#'
                                                onClick={() => {
                                                    setDefaults({
                                                        ...defaults,
                                                        languages: [
                                                            ...defaults.languages,
                                                            names.languages ||
                                                                'New Language'
                                                        ]
                                                    });
                                                    setNames({
                                                        languages: ''
                                                    });
                                                }}
                                            >
                                                <PlusOutlined /> Add Language
                                            </StyledAddButton>
                                        </StyledSelectDiv>
                                    </div>
                                )}
                            >
                                {defaults.languages.map(item => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label='Topics' name='topics'>
                            <Select
                                mode='multiple'
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <StyledDivider />
                                        <StyledSelectDiv>
                                            <StyledInput
                                                value={names.topics}
                                                onChange={event => {
                                                    setNames({
                                                        ...names,
                                                        topics:
                                                            event.target.value
                                                    });
                                                }}
                                            />
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                            <StyledAddButton
                                                href='#'
                                                onClick={() => {
                                                    setDefaults({
                                                        ...defaults,
                                                        topics: [
                                                            ...defaults.topics,
                                                            names.topics ||
                                                                'New Topic'
                                                        ]
                                                    });
                                                    setNames({
                                                        topics: ''
                                                    });
                                                }}
                                            >
                                                <PlusOutlined /> Add Topic
                                            </StyledAddButton>
                                        </StyledSelectDiv>
                                    </div>
                                )}
                            >
                                {defaults.topics.map(item => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Libraries' name='libraries'>
                            <Select
                                mode='multiple'
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <StyledDivider />
                                        <StyledSelectDiv>
                                            <StyledInput
                                                value={names.libraries}
                                                onChange={event => {
                                                    setNames({
                                                        ...names,
                                                        libraries:
                                                            event.target.value
                                                    });
                                                }}
                                            />
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                            <StyledAddButton
                                                href='#'
                                                onClick={() => {
                                                    setDefaults({
                                                        ...defaults,
                                                        libraries: [
                                                            ...defaults.libraries,
                                                            names.libraries ||
                                                                'New Library'
                                                        ]
                                                    });
                                                    setNames({
                                                        libraries: ''
                                                    });
                                                }}
                                            >
                                                <PlusOutlined /> Add Library
                                            </StyledAddButton>
                                        </StyledSelectDiv>
                                    </div>
                                )}
                            >
                                {defaults.libraries.map(item => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label='Source Code'
                            name='code'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a snippet of code!'
                                }
                            ]}
                        >
                            <Editor
                                value={value}
                                onValueChange={v => setValue(v)}
                                highlight={() => highlight(value)}
                                padding={10}
                                style={styles.root}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </StyledRow>
        );
};

const StyledRow = styled(Row)`
    min-height: calc(100vh - 48px);
`;

const StyledSelectDiv = styled.div`
    display: 'flex';
    flexwrap: 'nowrap';
    padding: 8px;
`;

const StyledDivider = styled(Divider)`
    margin: 4px 0px;
`;

const StyledInput = styled(Input)`
    flex: auto;
`;

const StyledAddButton = styled.a`
    flex: none;
    padding: 8px;
    display: block;
    cursor: pointer;
`;

const Line = styled.div`
    display: table-row;
    overflow: scroll;
`;
