import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { message, Table, Tag, Avatar } from 'antd';
import { API_HOST } from '../index';
import styled from 'styled-components';

export const Snippets = () => {
    const query = new URLSearchParams(useLocation().search);
    const [data, setData] = useState({ loading: true });

    useEffect(() => {
        const fetchSnippets = async () => {
            try {
                var response = await fetch(`${API_HOST}/v1/snippets`);
                var results = await response.json();
            } catch (e) {
                console.log(e);
            }
            if (!results || response.status !== 200) {
                setData({});
                message.error('Unable to fetch snippets, try again later.');
            } else setData(results);
        };
        fetchSnippets();
    }, []);

    console.log(data);
    console.log(query);

    const columns = [
        { title: 'Title', dataIndex: 'title', key: 'title' },
        { title: 'Language', dataIndex: 'language', key: 'language' },
        {
            title: 'Topics',
            dataIndex: 'topics',
            key: 'topics',
            render: tags => (
                <>
                    {(tags || []).map(tag => (
                        <Tag color='blue' key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </>
            )
        },
        {
            title: 'Libraries',
            dataIndex: 'libraries',
            key: 'libraries',
            render: tags => (
                <>
                    {(tags || []).map(tag => (
                        <Tag color='blue' key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </>
            )
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: author => {
                return (
                    <>
                        <Avatar
                            style={{
                                marginRight: 5,
                                verticalAlign: 'middle'
                            }}
                            size='small'
                            src={`https://cdn.discordapp.com/avatars/${author.id}/${author.avatar}.png`}
                        />
                        {author.username}
                    </>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: id => {
                return (
                    <>
                        <Link to={`/snippet/${id}`}>View</Link>
                    </>
                );
            }
        }
    ];
    console.log(data);
    return (
        <>
            <StyledTable
                scrollable
                loading={data.loading}
                columns={columns}
                dataSource={
                    data.loading || Object.keys(data).length === 0 ? null : data
                }
            />
        </>
    );
};

const StyledTable = styled(Table)`
    .ant-table-tbody {
        background: #262626;
    }
`;
