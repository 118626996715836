import React, { useEffect, useState, Fragment, createElement } from 'react';
import {
    message,
    Tag,
    Avatar,
    Descriptions,
    Button,
    Tabs,
    Popconfirm,
    Rate
} from 'antd';
import { ArrowLeftOutlined as ArrowLeft } from '@ant-design/icons';
import Highlight, { defaultProps } from 'prism-react-renderer';
import Editor from 'react-simple-code-editor';
import theme from 'prism-react-renderer/themes/vsDark';
import { API_HOST } from '../index';
import styled from 'styled-components';
import history from '../history';
import { Auth } from '../States';

const { TabPane } = Tabs;
const { Item } = Descriptions;

export const Snippet = ({ match }) => {
    const { id } = match.params;
    const [data, setData] = useState({ loading: true });
    const auth = Auth.useContainer();

    useEffect(() => {
        const fetchSnippets = async () => {
            try {
                var response = await fetch(`${API_HOST}/v1/snippets/${id}`);
                var results = await response.json();
            } catch (e) {
                console.log(e);
            }
            if (!results || response.status !== 200 || results.message) {
                setData({});
                message.error(
                    (results || {}).message ||
                        'Unable to fetch snippet, try again later.'
                );
            } else setData({ ...results, valid: true });
        };
        fetchSnippets();
    }, [id]);

    console.log(data);

    const onDelete = async () => {
        try {
            var response = await fetch(`${API_HOST}/v1/snippets/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Headers': 'X-Requested-With',
                    Authentication: localStorage.token
                }
            });
            var results = await response.json();
        } catch (e) {
            console.log(e);
            message.error('Unable to delete snippet, try again later.');
        }

        console.log(results);

        if (!results || results.message)
            message.error(
                (results || {}).message ||
                    'Unable to delete snippet, try again later.'
            );
        else history.push(`/snippets`);
    };

    /*const onRate = async value => {
        try {
            var response = await fetch(`${API_HOST}/v1/snippets/${id}/rate`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': 'X-Requested-With',
                    Authentication: localStorage.token
                },
                body: JSON.stringify(value)
            });
            var results = await response.json();
        } catch (e) {
            console.log(e);
            message.error('Unable to vote for snippet, try again later.');
        }
        if (!results || results.message)
            message.error(
                (results || {}).message ||
                    'Unable to vote for snippet, try again later.'
            );
        else message.success(`Successfully rated snippet ${value} stars.`);
    };*/

    const styles = {
        root: {
            boxSizing: 'border-box',
            fontFamily: '"Dank Mono", "Fira Code", monospace',
            ...theme.plain
        }
    };

    const highlight = code => (
        <Highlight {...defaultProps} theme={theme} code={code} language='jsx'>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Fragment style={{ overflow: 'scroll !important' }}>
                    {tokens.map((line, i) => (
                        <Line key={i} {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </Line>
                    ))}
                </Fragment>
            )}
        </Highlight>
    );

    const Content = () => {
        return (
            <>
                <Descriptions
                    title={createElement(
                        'div',
                        {},
                        <>
                            <span>
                                <ArrowLeft
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                        history.push('/snippets');
                                    }}
                                />
                                {data.title}
                            </span>
                            <StyledDescription>
                                {data.description}
                            </StyledDescription>
                        </>
                    )}
                    bordered
                    layout='vertical'
                    column={{ xxl: 6, xl: 5, lg: 4, md: 3, sm: 2, xs: 1 }}
                >
                    <Item label='Language' span={1}>
                        {data.language}
                    </Item>
                    <Item label='Topics' span={1}>
                        {(data.topics || []).map(tag => (
                            <Tag color='blue' key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </Item>
                    <Item label='Libraries' span={1}>
                        {(data.libraries || []).map(tag => (
                            <Tag color='blue' key={tag}>
                                {tag}
                            </Tag>
                        ))}
                    </Item>
                    <Item label='Author' span={1}>
                        <Avatar
                            style={{
                                marginRight: 5,
                                verticalAlign: 'middle'
                            }}
                            size='small'
                            src={`https://cdn.discordapp.com/avatars/${data.author.id}/${data.author.avatar}.png`}
                        />
                        {data.author.username}
                    </Item>
                    <Item label='Views' span={1}>
                        {data.views}
                    </Item>
                    <Item label='Rating' span={1}>
                        <Rate defaultValue={2.5} disabled></Rate>
                    </Item>
                </Descriptions>
                <Tabs defaultActiveKey='1'>
                    <TabPane tab='Code' key='1'>
                        <StyledEditor
                            value={data.code}
                            onValueChange={() => {}}
                            highlight={() => highlight(data.code)}
                            padding={10}
                            style={styles.root}
                        />
                    </TabPane>
                    <TabPane tab='Reviews' key='2'></TabPane>
                    {auth.state.id === data.author.id && (
                        <TabPane tab='Actions' key='3'>
                            <Popconfirm
                                title='Are you sure？'
                                onConfirm={onDelete}
                            >
                                <StyledButton type='primary' danger>
                                    Delete
                                </StyledButton>
                            </Popconfirm>
                            <StyledButton
                                type='primary'
                                onClick={() => {
                                    history.push(`/edit/${id}`);
                                }}
                            >
                                Edit
                            </StyledButton>
                        </TabPane>
                    )}
                </Tabs>
            </>
        );
    };

    return <>{data.valid && <Content />}</>;
};

const Line = styled.div`
    display: table-row;
    overflow: scroll;
`;

const StyledButton = styled(Button)`
    margin: 8px;
`;

const StyledEditor = styled(Editor)`
    border-radius: 2px;
    overflow-x: scroll;
`;

const StyledDescription = styled.p`
    font-weight: normal;
    color: rgba(255, 255, 255, 0.65);
`;
