import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { Auth } from '../States/Auth';

/**
 * extensions:
 * @param {Boolean} protected
 * @param {[States]} dependencies
 */
export const Route = (props = {}) => {
    const AuthContainer = Auth.useContainer();

    if (props.protected) return <AuthContainer.ProtectedRoute {...props} />;
    else return <ReactRoute {...props} />;
};
