import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { Row, Col, message } from 'antd';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { API_HOST } from '../index';

const useAuth = (initialState = { loading: true }) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        login();
    }, []);

    const login = async () => {
        setState({ loading: true });
        if (!localStorage.token) return setState({});

        try {
            var response = await fetch(`${API_HOST}/v1/auth/getUser`, {
                headers: {
                    'Access-Controll-Allow-Headers': 'X-Requested-With',
                    Authentication: localStorage.token
                }
            });
            var results = await response.json();
        } catch (e) {
            console.log(e);
            message.error('Error logging in, try again later.');
        }

        if (!results || response.status === 206) setState({});
        else setState(results);
    };

    const logout = async () => {
        await fetch(`${API_HOST}/v1/auth/logout`, {
            headers: {
                Authentication: localStorage.token
            }
        });
        localStorage.removeItem('token');
        setState({});
    };

    const greet = () => {
        if (state.loading) return ' Loading...';
        else return ` Hello ${state.username}`;
    };

    const Callback = ({ match }) => {
        const { token } = match.params;
        localStorage.setItem('token', token);
        return <Redirect to={'/'} />;
    };

    const ProtectedRoute = props => {
        if (isLoggedIn) return <Route {...props} />;
        else
            return (
                <RowContainer type='flex' justify='center' align='middle'>
                    <Col>
                        Sorry, you need to be logged in to view this page.
                    </Col>
                </RowContainer>
            );
    };

    const isLoggedIn = !!state.id;

    return {
        state,
        login,
        logout,
        greet,
        isLoggedIn,
        ProtectedRoute,
        Callback
    };
};

export const Auth = createContainer(useAuth);

const RowContainer = styled(Row)`
    min-height: calc(100vh - 48px);
`;
