import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useLayout = (initialState = { collapsed: false }) => {
    const [state, setState] = useState(initialState);

    const toggleCollapsed = () => {
        setState({ ...state, collapsed: !state.collapsed });
    };

    return { state, toggleCollapsed };
};

export const Layout = createContainer(useLayout);
