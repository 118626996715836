import React, { useState } from 'react';
import styled from 'styled-components';

import history from '../history';

import { Form, Button, Row, Col, Select, Input, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

export const Search = () => {
    const [state, setState] = useState({
        languages: [],
        topics: [],
        libraries: []
    });

    // TODO: Populate languages, topics, & libraries from API (do this later, API isn't complete yet)
    setTimeout(() => {
        setState({
            ...state,
            languages: ['JavaScript', 'Python', 'Rust'],
            topics: ['Discord Bot', 'Website', 'Video Game'],
            libraries: ['discord.js', 'react', 'discord.py', 'quick.db']
        });
    }, 1000);

    /** Get values from form items & redirect to
     *  /snippets?q=<Query>&lang=<Language>&topics=[<Topics>]&libraries=[<Libraries>]
     *  If a item's value is empty, don't include it in the search url.
     */
    const onFinish = values => {
        let query = Object.keys(values)
            .filter(key => values[key])
            .map(key => key + '=' + encodeURIComponent(values[key]))
            .join('&');

        if (!query) message.error('Invalid Search');
        else history.push(`/snippets?${query}`);
    };

    return (
        <RowContainer type='flex' justify='center' align='middle'>
            <Col sm={16} md={8}>
                <FormComponent
                    name='basic'
                    initialValues={{
                        remember: true
                    }}
                    layout='vertical'
                    onFinish={onFinish}
                >
                    {/** Search Box */}
                    <Form.Item label='Search' name='query'>
                        <Input prefix={<SearchOutlinedIcon />} />
                    </Form.Item>

                    {/** Language */}
                    <Form.Item label='Language' name='language'>
                        <Select>
                            {state.languages.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/** Topics */}
                    <Form.Item label='Topics' name='topics'>
                        <Select mode='multiple'>
                            {state.topics.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/** Libraries */}
                    <Form.Item label='Libraries' name='libraries'>
                        <Select mode='multiple'>
                            {state.libraries.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/** Submit Button */}
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Search
                        </Button>
                    </Form.Item>
                </FormComponent>
            </Col>
        </RowContainer>
    );
};

const RowContainer = styled(Row)`
    min-height: calc(100vh - 48px);
`;

const FormComponent = styled(Form)`
    margin: '5%';
    width: '90%';
`;

const SearchOutlinedIcon = styled(SearchOutlined)`
    margin-right: 5px;
`;
